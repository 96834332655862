<template>
  <v-container grid-list-s class="pt-4">
    <v-row>
      <v-col col="3" md="3" class="d-none d-sm-flex"><SideNav /> </v-col>
      <v-divider vertical />
      <v-col col="8" md="8" sm="8">
        <v-row>
          <v-container>
            <v-alert
              v-if="changeSuccessful"
              :value="changeSuccessful"
              type="success"
              dark
              border="top"
              icon="mdi-home"
              transition="scale-transition"
            >
              {{ changeMessage }}
            </v-alert>
            <h2 class="text-h4 font-weight-bold primary--text ">
              {{ $t('settingsTitle') }}
            </h2>
            <v-divider class="mt-4" />
            <h3 class="text-subtitle font-weight-bold secondary--text my-4">
              {{ $t('changePassword') }}
            </h3>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              name="change_password"
              @submit.prevent=""
            >
              <v-text-field
                v-model="currentPassword"
                outlined
                type="password"
                :label="$t('currentPassword')"
                color="primary"
                maxlength="255"
                required
                :rules="[oldPasswordRules]"
                @input="oldPasswordError = ''"
              />
              <v-text-field
                v-model="newPassword"
                outlined
                type="password"
                :label="$t('newPassword')"
                color="primary"
                maxlength="255"
                required
                :rules="[newPasswordRules]"
              />

              <v-text-field
                v-model="confirmNewPassword"
                outlined
                type="password"
                :label="$t('confirmNewPassword')"
                color="primary"
                :rules="[passwordRules]"
                required
              />
              <v-btn
                depressed
                :disabled="!valid"
                color="primary"
                large
                type="submit"
                class="float-right"
                @click="changeUserPassword"
              >
                {{ $t('changePassword') }}
              </v-btn>
            </v-form>
          </v-container>
        </v-row>
        <v-row>
          <v-container>
            <v-divider class="mt-4" />
            <h3 class="text-subtitle font-weight-bold secondary--text my-4">
              {{ $t('deleteAccount') }}
            </h3>
            <p>{{ $t('deleteAccountText') }}</p>
            <v-btn
              depressed
              color="error"
              large
              class="float-right"
              @click="confirmIsOpen = true"
            >
              {{ $t('deleteAccount') }}
            </v-btn>
          </v-container></v-row
        >
        <v-dialog v-model="confirmIsOpen" max-width="400">
          <v-card>
            <v-card-title>
              {{ $t('deleteAccountConfirm') }}
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="error darken-1" @click="deleteAccount">
                {{ $t('deleteAccount') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Vue from 'vue'
import { mapActions } from 'vuex'
import SideNav from '@/components/SideNav.vue'

export default Vue.extend({
  components: {
    SideNav
  },
  data: () => ({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    valid: true,
    changeSuccessful: false,
    changeMessage: '',
    oldPasswordError: '',
    newPasswordError: '',
    confirmIsOpen: false
  }),
  methods: {
    ...mapActions(['changePassword', 'deleteUserAccount', 'logout']),
    passwordRules(value) {
      if (!value) {
        return this.$t('requiredError')
      } else {
        if (value !== this.newPassword) {
          return this.$t('passwordNotMatching')
        } else return true
      }
    },
    oldPasswordRules(value) {
      if (!value) {
        return this.$t('requiredError')
      } else {
        if (this.oldPasswordError) {
          return this.oldPasswordError
        } else return true
      }
    },
    newPasswordRules(value) {
      if (!value) {
        return this.$t('requiredError')
      } else {
        if (this.newPasswordError) {
          return this.newPasswordError
        } else return true
      }
    },
    async changeUserPassword() {
      if (this.$refs.form.validate()) {
        await this.changePassword({
          old_password: this.currentPassword,
          new_password: this.newPassword
        })
          .then(res => {
            if (res.status !== 200) {
              this.oldPasswordError =
                res.data.old_password && res.data.old_password[0]
              this.newPasswordError =
                res.data.new_password && res.data.new_password[0]
              this.$refs.form.validate()
            }

            this.changeSuccessful = true
            this.changeMessage = res.data
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    deleteAccount() {
      this.confirmIsOpen = false
      this.deleteUserAccount()
        .then(() => {
          this.logout().then(() => {
            this.$router.push('/')
          })
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
})
</script>
